import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { HomeWarper } from './GlobalWrapper.Styles';
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from '../../components/Loading/Loading';
import { getUserData } from '../../core/util/getUserData';
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.length == 1) {
      navigate(getUserData()?.currentPage);
    }
  }, []);
  return (
    <HomeWarper>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </HomeWarper>
  );
};

export default Home;
