import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { isUserExist } from '../../core/services/auth.service';
import { Wrapper } from './Login.style';
import { toast } from 'react-toastify';
import respiverseLogo from '../../assets/images/logo/gray_logo.png';
import loginButton from '../../assets/images/login/Group171239.png';
import gskLogo from '../../assets/images/logo/white_logo.png';

const Login = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("res") 
    try {
      console.log("try") 

      const res = await isUserExist(e.target.email.value);
      console.log(res) 
      localStorage.setItem('user', JSON.stringify(res));
      localStorage.setItem('email', e.target.email.value);
      console.log(res.is_admin) 
      if (res.is_admin) {
        nav('/admin');
        return;
      }
      nav('/home');
      setLoading(false);
    } catch (err) {
      console.log(err) 
      if (err.request.status === 0) {
        toast.error(`${err.code}
         \n Please Check Your Internet Connection`);
      } else if (err.request.status === 404) {
        toast.error('invalid email', {
          toastId: 'invalid-login',
        });
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);
  return (
    <Wrapper>
      {loading && <Loading />}
      <form onSubmit={onSubmit}>
        <div className="form-container">
          <img src={respiverseLogo} width={55} alt="respiverse logo" />
          <div className="form-container__title">Log in</div>
          <div className="form-container__form-control">
            <label htmlFor="email">Email</label>
            <input id="email" type="email" placeholder="Enter your email" />
          </div>
          <button className="form-container__btn" type="submit" value="Login">
            <img src={loginButton} alt="login button" />
          </button>
        </div>
      </form>
      <img className="gsk-logo" src={gskLogo} alt="gsk logo" />
    </Wrapper>
  );
};

export default Login;
