import styled from "styled-components";


export const HomeWarper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .container {
    ul.routing-list{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        li.routing-list-ticket{
            width: 33%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem 0;
            h1{
              height: 70px;
            }
        }
    }
  }
`